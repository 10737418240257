<style lang="scss" scoped>
.el-menu {
  border-right: none;
  overflow-y: auto;
  overflow-x: hidden;
}
.el-submenu {
  .el-menu-item {
    background-color: #1f2d3d !important;
    text-align: center;
    &:hover {
      background-color: #001528 !important;
    }
  }
}
.iconfont{
  margin-right: 5px;
}
</style>
<template>
  <el-menu
    :default-active="state.default_active"
    background-color="#304156"
    text-color="#fff"
    active-text-color="#409EFF"
    :collapse="props.collapse"
    class="flex1 width_100"
    :unique-opened="true"
    :router="true"
    @select="selectSubMenu"
  >
    <el-menu-item :index="'home'">
      <i :class="['iconfont', `icon-dashboard`]"></i>
      <template #title>首頁</template>
    </el-menu-item>
    <template v-for="item in store.state.app.allMenuList" :key="item.id">
      <el-submenu
        :index="
          item.path.slice(0, 1) == '/'
            ? item.path.replace(/^\/(.+)$/, '$1')
            : item.path
        "
        v-if="item.children.length !== 0"
      >
        <template #title>
          <i :class="['iconfont', `icon-${item.icon}`]"></i>
          <span>{{ item.menu_name }}</span>
        </template>
        <el-menu-item
          :index="value.path"
          v-for="value in item.children"
          :key="value.id"
          >{{ value.menu_name }}</el-menu-item
        >
      </el-submenu>
      <el-menu-item
        :index="
          item.path.slice(0, 1) == '/'
            ? item.path.replace(/^\/(.+)$/, '$1')
            : item.path
        "
        v-else
      >
        <i :class="['iconfont', `icon-${item.icon}`]"></i>
        <template #title>{{ item.menu_name }}</template>
      </el-menu-item>
    </template>
  </el-menu>
</template>

<script>
import { reactive, getCurrentInstance, watch } from "vue";
import { useStore } from "vuex";
import route from "@/router/path/need_jurisdiction";

export default {
  props: {
    collapse: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    // variable
    const store = useStore();

    // 数据
    const state = reactive({
      allMenuList: [],
      default_active: "",
      crumbs: [], // 面包屑
    });

    // 生命周期
    /* created */
    // const proxy = getCurrentInstance().appContext.config.globalProperties;
    // const { $router: router } = proxy;
    // const { children: path } = route[0];
    store.dispatch("REQ_ALLMENULIST");
    /* watch */
    watch(
      () => store.state.app.allMenuList,
      (val, old) => {
        state.allMenuList = val;
        initPath(state.default_active);
      }
    );

    state.default_active = store.state.app.selePath || "home";

    watch(
      () => store.state.app.selePath,
      (val, old) => {
        if (state.allMenuList.length !== 0) state.default_active = val;
      }
    );

    watch(
      () => state.default_active,
      (val, old) => {
        initPath(val);
      }
    );

    //methods
    const selectSubMenu = (index) => {
      // state.default_active = index;
    };

    const initPath = (val) => {
      if (val === "home") return store.commit("SET_CRUMBS", []);
      const one_menu = [];
      const two_menu = [];
      state.allMenuList.forEach((item) => {
        if (item.children.length == 0) {
          one_menu.push(item);
        } else {
          two_menu.push(item);
        }
      });
      if (
        two_menu.some((item) => {
          return Object.values(item.children).some((item) => item.path === val);
        })
      ) {
        const arr = two_menu.filter((item) => {
          return Object.values(item.children).some((item) => item.path === val);
        });

        const arr1 = Object.values(arr[0].children).filter(
          (item) => item.path === val
        );
        state.crumbs = [arr[0].menu_name, arr1[0].menu_name];
      } else {
        const arr = one_menu.filter((item) => item.path === "/" + val);
        state.crumbs = [arr[0].menu_name];
      }
      store.commit("SET_CRUMBS", state.crumbs);
    };

    return {
      state,
      props,
      store,
      selectSubMenu,
    };
  },
};
</script>
