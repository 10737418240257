<style lang="scss" scoped>
.head {
  min-width: 300px;
}
.setting {
  p {
    text-align: center;
    line-height: 45px;
    cursor: pointer;
    &:hover {
      background-color: #ecf5ff;
      color: #66b1ff;
    }
  }
}
</style>
<template>
  <div class="flex_lr_m width_100 head">
    <el-breadcrumb separator-class="el-icon-arrow-right" class="breadcrumb">
      <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
      <el-breadcrumb-item
        v-for="(item, index) in store.state.app.crumbs"
        :key="index"
      >
        {{ item }}
      </el-breadcrumb-item>
    </el-breadcrumb>
    <div>
      <el-button
        type="primary"
        size="mini"
        style="margin-right: 10px"
        @click="loginOut"
        plain
        >登出</el-button
      >
    </div>
    <el-dialog title="提示" v-model="state.dialogVisible" width="400px">
      <span>確定登出嗎？</span>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="state.dialogVisible = false">取消</el-button>
          <el-button type="primary" @click="out">確定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
import { reactive, getCurrentInstance } from "vue";
import { useStore } from "vuex";

export default {
  setup() {
    const state = reactive({
      dialogVisible: false,
    });

    // 生命周期
    /* created */
    const proxy = getCurrentInstance().appContext.config.globalProperties;
    const { $utils } = proxy;
    const store = useStore();

    // /* watch */

    // watch(
    //   () => store.state.app.crumbs,
    //   (val, old) => {
    //     console.log(val);
    //   }
    // );

    // methods
    const loginOut = () => {
      state.dialogVisible = true;
    };

    const out = () => {
      state.dialogVisible = false;
      $utils.Setcookie("token", "", true);
      $utils.Setcookie("vue_admin_template_token", "", true); // 未解决的坑
      window.location.reload();
    };

    return {
      state,
      loginOut,
      out,
      store,
    };
  },
};
</script>
