<style lang="scss" scoped>
.main {
  min-width: 300px;
}
.el-aside {
  background-color: #304156;
  transition: all 0.6s !important;
  overflow: hidden;
}
.toogle {
  padding: 5px 0;
  text-align: center;
  color: white;
  background-color: rgba(31, 45, 61, 0.4);
  letter-spacing: 2px;
}
.logo {
  height: 50px;
  background: #2b2f3a;
  .admin_name {
    margin-left: 15px;
    color: white;
    font-weight: 600;
  }
}
.el-header {
  height: 50px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.1);
}

/*定义滚动条高宽及背景 高宽分别对应横竖滚动条的尺寸*/
::-webkit-scrollbar {
  width: 5px;
  background-color: #f5f5f5;
}

/*定义滚动条轨道 内阴影+圆角*/
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

/*定义滑块 内阴影+圆角*/
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #c8c8c8;
}
</style>
<template>
  <el-container class="height_100">
    <el-aside
      :width="!state.collapse ? 200 + 'px' : 64 + 'px'"
      class="flex_line_c aside"
    >
      <div class="logo flex_c_m cursor width_100">
        <el-image
          style="width: 40px; height: 40px"
          :src="require('@/assets/logo.png')"
          fit="cover"
        ></el-image>
        <p class="admin_name fonts-14" v-show="!state.collapse">
          {{ state.app_name }}
        </p>
      </div>
      <div class="toogle sizing cursor width_100" @click="toogle_aside">
        |||
      </div>
      <AsideNav :collapse="state.collapse"></AsideNav>
    </el-aside>
    <el-container>
      <el-header class="flex_m">
        <Head />
      </el-header>
      <el-main class="main">
        <router-view />
      </el-main>
    </el-container>
  </el-container>
</template>

<script>
import AsideNav from "./components/aside";
import Head from "./components/head";
import { reactive } from "vue";

export default {
  components: {
    AsideNav,
    Head,
  },
  setup() {
    // 数据
    const state = reactive({
      collapse: sessionStorage.getItem("collapse") == "1", // 是否折叠侧边栏
      LOGO_URL: '@/assets/logo.png',
      app_name: process.env.VUE_APP_NAME,
    });
    //methods
    const toogle_aside = () => {
      state.collapse = !state.collapse;
      sessionStorage.setItem("collapse", Number(state.collapse));
    };

    return {
      state,
      toogle_aside,
    };
  },
};
</script>
